/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/***************** Variables **********************/
:root {
    --dark-green: #325f80;
    --white: #ffffff;
    --light-blue: #8fbfe2;
    --light-grey: #868686;
    --dark-grey: #515151;
    --blue: #1e88e5;
    --lite-border: #eaeaea;
    --light-brown: #c4ba82;
    --dark-blue: #1e3c50;
    --orange: #df6535;
    --background-grey: #f7f7fb;
    --title-color: #595959;
    --title-light: #707070;
    --red: #b71c1c;
    --input-title: #0000008a;
    --input-active: #1565c0;
    --input-fill: #000000de;
    --table-title-bg: #d2d2d2;
    --bar-orange: #f57c00;
    --bar-red: #dd0d0d;
    --bar-green: #4caf50;
    --green: #00c853;
    --table-grey: #edf0f2;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ::-webkit-scrollbar {
    display: none;
} */

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
