@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap'); */

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/***************** Variables **********************/
:root {
    --dark-green: #325f80;
    --white: #ffffff;
    --light-blue: #8fbfe2;
    --light-grey: #868686;
    --dark-grey: #515151;
    --blue: #1e88e5;
    --lite-border: #eaeaea;
    --light-brown: #c4ba82;
    --dark-blue: #1e3c50;
    --orange: #df6535;
    --background-grey: #f7f7fb;
    --title-color: #595959;
    --title-light: #707070;
    --red: #b71c1c;
    --input-title: #0000008a;
    --input-active: #1565c0;
    --input-fill: #000000de;
    --table-title-bg: #d2d2d2;
    --bar-orange: #f57c00;
    --bar-red: #dd0d0d;
    --bar-green: #4caf50;
    --green: #00c853;
    --table-grey: #edf0f2;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ::-webkit-scrollbar {
    display: none;
} */

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.loading-sync {
    animation: App-logo-spin infinite 1s linear;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* .Toastify__progress-bar {
    display: none;
} */

.Toastify__progress-bar--animated {
    display: none;
}

.Toastify__toast--success {
    background: #43a047 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}

.Toastify__toast--info {
    background: #1976d2 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}
.Toastify__toast--warning {
    background: #ffa000 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}
.Toastify__toast--error {
    background: #d32f2f 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}

/* .MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
} */

a {
    text-decoration: none;
}

.MuiFormHelperText-root {
    letter-spacing: unset !important;
}

/* .MuiTableCell-root {
    vertical-align: top !important;
} */

.MuiSvgIcon-colorPrimary {
    color: #1E88E5 !important;
}

#custom-toast {
    z-index: 9999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #d32f2f;
    border-radius: 4px !important;
    color: #fff;
    position: absolute;
    top: 20px;
    min-height: 64px;
    width: 330px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 1px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0.1px;
    animation: slide 0.5s forwards;
    right: -350px;
}

#custom-toast > #toast-message {
    max-width: 215px;
    word-break: break-all;
}

#custom-toast > #toast-timer {
    position: absolute;
    right: 40px;
}

#custom-toast svg {
    margin: 5px;
}

#custom-toast > svg:last-child {
    position: absolute;
    right: 0px;
}

@keyframes slide {
    100% { right: 20px; }
}

.removeAnimation {
    right: 20px !important;
    animation: slideOut 0.5s forwards !important;
}

@keyframes slideOut {
    100% { right: -280px; }
}
